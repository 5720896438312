export enum ExpirableDocumentType {
    EAD = 'EAD',
    STATE_ID = 'STATE_ID', // OR LEARNER PERMIT, OR DRIVER LICENSE
    GREEN_CARD = 'GREEN_CARD',
    CITIZEN_PASSPORT = 'CITIZEN_PASSPORT',
    BUSINESS_GENERAL_LIABILITY_INSURANCE = 'BUSINESS_GENERAL_LIABILITY_INSURANCE',
    BUSINESS_PROFESSIONAL_LIABILITY_INSURANCE = 'BUSINESS_PROFESSIONAL_LIABILITY_INSURANCE',
    BUSINESS_WORKERS_COMPENSATION_INSURANCE = 'BUSINESS_WORKERS_COMPENSATION_INSURANCE',
    BUSINESS_DISABILITY_INSURANCE = 'BUSINESS_DISABILITY_INSURANCE',
}

export enum EmployeesHRDocumentType {
    FORM_I9 = 'FORM_I9',
    FORM_W4 = 'FORM_W4',
    FORM_IT2104 = 'FORM_IT2104',
}
